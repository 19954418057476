exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-concepts-js": () => import("./../../../src/templates/concepts.js" /* webpackChunkName: "component---src-templates-concepts-js" */),
  "component---src-templates-football-js": () => import("./../../../src/templates/football.js" /* webpackChunkName: "component---src-templates-football-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-representation-js": () => import("./../../../src/templates/representation.js" /* webpackChunkName: "component---src-templates-representation-js" */),
  "component---src-templates-shop-brand-js": () => import("./../../../src/templates/shop-brand.js" /* webpackChunkName: "component---src-templates-shop-brand-js" */),
  "component---src-templates-shop-tag-js": () => import("./../../../src/templates/shop-tag.js" /* webpackChunkName: "component---src-templates-shop-tag-js" */)
}

