import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        {/* <div className='footer__inner'>

          <div className='footer__col'>
            <ul className='footer__nav'>
              <li><Link to='/projects'>Contact Us</Link></li>
              <li><Link to='#'>Subscribe to our Journal</Link></li>
              <li><Link to='#'>Instagram</Link></li>
              <li><Link to='#'>Twitter</Link></li>
              <li><Link to='#'>Pinterest</Link></li>
              <li><Link to='#'>LinkedIn</Link></li>
            </ul>
          </div>

          <div className='footer__col'>
            <ul className='footer__nav-left'>
              <li>
                <span className='footer__copyright'>
                 &copy; 2022 Divock Origi. All rights reserved
                </span>
              </li>

              <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
            </ul>
          </div>
        </div> */}
      </footer>
    )
  }
}

export default Footer
