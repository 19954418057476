import React, { Component } from 'react'
import Link from '../utils/link'

class Header extends Component {

  state = {
    offCanvas: false
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    if (typeof window !== 'undefined') {
      setTimeout(() => {
        let pageTitle = document.body.className;

        const span = document.getElementById('header__page-title');
        span.textContent = pageTitle;
      }, 500);
    }

    return (
      <>
        <header className='header'>
          <div className='header__inner'>

            <div className='header__col'>
              <nav className='header__nav'>
                <ul className='header__nav--desktop'>
                  {/* <li><Link to='#' {...props}>About</Link></li>
                  <li><Link to='#' {...props}>Football</Link></li>
                  <li><Link to='#' {...props}>Concepts</Link></li>
                  <li><Link to='#' {...props}>Store</Link></li> */}

                  <li><Link to='/about' {...props}>About</Link></li>
                  <li><Link to='/football' {...props}>Football</Link></li>
                  <li><Link to='/concepts' {...props}>Concepts</Link></li>
                  {/* <li>
                    <Link to='/shop/collection' {...props}>
                      Store
                    </Link>
                  </li> */}

                  {/* <li className='nav-cart'>
                    <Link to='/cart/' {...props}>
                      Cart
                      <span>({ this.props.cartCount })</span>
                    </Link>
                  </li> */}

                  <li><Link to='/representation' {...props}>Representation</Link></li>
                </ul>

                <span id='header__page-title' className='header__page-title'>

                </span>

                <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                  <span className='lines'></span>
                </button>

                <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
                  <div className='off-canvas__inner'>
                    <nav className='off-canvas__nav'>
                      <ul>
                        <li><Link to='/' {...props}>Home</Link></li>
                        <li><Link to='/about' {...props}>About</Link></li>
                        <li><Link to='/football' {...props}>Football</Link></li>
                        <li><Link to='/concepts' {...props}>Concepts</Link></li>
                        {/* <li><Link to='/shop/collection' {...props}>Store</Link></li> */}
                        <li><Link to='/representation' {...props}>Representation</Link></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </nav>
            </div>

            <div className='header__col'>
              <div className='header__logo'>
                {/* <Link to='/' {...props}>
                  <img className='large' src={Logo} alt='Divock Origi' />
                </Link> */}
              </div>
            </div>

          </div>
        </header>
      </>
    )
  }
}

export default Header
