import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Client from 'shopify-buy'
import { ParallaxProvider } from 'react-scroll-parallax';


import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'
// import Landing from '../components/landing'

class Layout extends Component {

  state = {
    isCartOpen: false,
    cartRequest: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {}
  }

  componentDidMount() {
    this.Cookies = require('cookies-js')

    let checkoutId = this.Cookies.get('checkoutId')

    this.client = Client.buildClient({
      domain: 'dicock-origi.myshopify.com',
      storefrontAccessToken: 'a6c8e5651bbac90ad83793fbda6f0cda'
    })
    this.client.checkout.create().then((checkout) => {
      // Do something with the checkout
      this.setState({ checkout })
    })

    if (checkoutId) {
      // Find old checkout
      this.client.checkout.fetch(checkoutId).then((checkout) => {
        // Do something with the checkout
        this.setState({ checkout })
      }).catch(error => {
        // Checkout expired, initialise new one
        this._initialiseCart()
      });
    } else {
      // Initialise new Checkout
      this._initialiseCart()
    }
  }

  _initialiseCart() {
    this.client.checkout.create().then((checkout) => {
      // Do something with the checkout
      this.setState({ checkout })
      this.Cookies.set('checkoutId', checkout.id)
    })
  }

  _addVariantToCart(variantId, quantity){
    this.setState({ isCartOpen: true, cartRequest: true })

    if (variantId.indexOf('Shopify__ProductVariant__') !== -1)
      variantId = variantId.split('Shopify__ProductVariant__')[1]

    const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
    const checkoutId = this.state.checkout.id

    return this.client.checkout.addLineItems(checkoutId, lineItemsToAdd)
      .then(checkout => this.setState({ checkout, cartRequest: false }))
  }

  _updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout.updateLineItems(checkoutId, lineItemsToUpdate)
      .then(checkout => this.setState({ checkout, cartRequest: false }))
  }

  _removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout.removeLineItems(checkoutId, [lineItemId])
      .then(checkout => this.setState({ checkout, cartRequest: false }))
  }

  render() {
    let { children, location, pageContext } = this.props
    let { checkout, isCartOpen, cartRequest } = this.state

    children = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        addVariantToCart : this._addVariantToCart.bind(this),
        updateQuantityInCart : this._updateQuantityInCart.bind(this),
        removeLineItemInCart : this._removeLineItemInCart.bind(this),
        checkout : checkout,
      })
    )

    return (
      <>
       <ParallaxProvider>
          <Header 
          cartRequest={cartRequest} 
          // cartCount={checkout.lineItems.length}
          cartCount={checkout?.lineItems?.length || 0} 
          context={pageContext} 
          />
          <main>{ children }</main>
          {/* <Landing /> */}
          <Footer />
        </ParallaxProvider>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
